import React, { FC } from 'react'
import cn from 'classnames'
import ArrowDownSvg from '../../public/icons/arrow-down.svg'
import ShortArrowSvg from '../../public/icons/short-arrow.svg'
import Link from 'next/link'
import Image from '../common/Image'
import { IData } from '../../contexts/WorkContext'
import styles from './VacanciesList.module.scss'
import Tags from '../news/Tags'

interface IVacanciesList {
  jobsData: IData
  titleAsTag?: boolean
  className?: string
}

const VacanciesList: FC<IVacanciesList> = ({
  jobsData,
  className,
  titleAsTag,
}) => {
  return (
    <div className={cn(styles.vacancies, className)}>
      <div
        className={cn(
          styles.vacanciesTitle,
          titleAsTag && styles.vacanciesTitleShort
        )}
      >
        {titleAsTag ? (
          <Tags
            tags={[{ id: 'vacancies-list', name: 'Вакансии', slug: 'jobs' }]}
          />
        ) : (
          <h2 className="h3">
            Вакансии{' '}
            <sup className={styles.sup}>{jobsData?.pageInfo?.totalCount}</sup>
            <ArrowDownSvg />
          </h2>
        )}

        <Link href="/tag/jobs" className={styles.readAll}>
          Смотреть все <ShortArrowSvg />
        </Link>
      </div>

      <ul className={styles.vacanciesList}>
        {jobsData.nodes?.slice(0, 8)?.map((node) => (
          <li key={node.id}>
            <Link href={node.slug} className={styles.vacanciesLink}>
              {node?.authors[0]?.picture?.url && (
                <Image
                  width={30}
                  height={30}
                  src={node.authors[0]?.picture?.url}
                  alt={node.authors[0].name}
                  skipSizes
                />
              )}

              <p className={styles.vacanciesItemTitle}>{node.title}</p>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default VacanciesList
