import React, { FC, useContext } from 'react'
import MaxWidth from '../layout/MaxWidth'
import Link from 'next/link'
import cn from 'classnames'
import { useRouter } from 'next/router'
import { WorkContext } from '../../contexts/WorkContext'
import CommonSlider from '../main/CommonSlider'
import Post from '../news/Post'
import VacanciesList from './VacanciesList'
import ReviewCard from '../ReviewCard/ReviewCard'
import styles from './Work.module.scss'

const Work: FC = () => {
  const { reviewSlider, workArticlesData, jobsData } = useContext(WorkContext)

  const { route } = useRouter()
  const isWorkPage = route === '/work'

  const renderTitle = (children: React.ReactNode) => {
    if (isWorkPage) {
      return (
        <div className={styles.title}>
          <h1>Карьера</h1>
          {children}
        </div>
      )
    } else {
      return (
        <h2 className={cn('h3', styles.title, styles.titleLink)}>
          <Link href="/work">Карьера {children}</Link>
        </h2>
      )
    }
  }

  const workSlider = () => {
    return (
      <CommonSlider
        title={renderTitle(
          <>
            <sup className={styles.sup}>
              {workArticlesData?.pageInfo?.totalCount}
            </sup>
          </>
        )}
        items={workArticlesData?.nodes?.map((node) => (
          <Post
            bigTitle
            showTags
            imageMaxWidth={400}
            key={node.id}
            data={node}
          />
        ))}
        slidesPerView={2}
        className={styles.sliderInner}
        work
      />
    )
  }

  return (
    <>
      <div className={styles.sliderMobile}>{workSlider()}</div>
      <MaxWidth>
        <div className={styles.grid}>
          <div className={styles.slider}>{workSlider()}</div>
          <VacanciesList className={styles.vacancies} jobsData={jobsData} />
        </div>
      </MaxWidth>
      <div className={styles.reviewsSlider}>
        <CommonSlider
          title="Личный опыт работы"
          items={reviewSlider.map((review) => (
            <ReviewCard
              key={review.id}
              {...review}
              rating={isWorkPage && review.rating}
            />
          ))}
          slidesPerView={3}
          oneSlideOnMobile
        />
      </div>
    </>
  )
}

export default Work
