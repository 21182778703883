import React from 'react'
import SocialsBanner from '../main/SocialsBanner'
import Post from '../news/Post'
import CommonSlider from '../main/CommonSlider'
import WorkProvider from '../../contexts/WorkContext'
import {
  PreviewPostFragment,
  useBlogsQuery,
  useMainPageInfoQuery,
  useNowReadingQuery,
} from '../../queries/generated/blog/schema'
import Work from '../work/Work'
import { BLOG_CLIENT } from '../../lib/constants'
import styles from '../../features/main/CommonSlider.module.scss'
import Stories from '../Stories'
import CommonBanner from '../main/CommonBanner'
import rootStyles from './MainPageContent.module.scss'

const MainPageContent = () => {
  const {
    data: { interviewData, reviewsData, telegramData, stData },
  } = useMainPageInfoQuery({ context: BLOG_CLIENT })
  const { data: nowReading } = useNowReadingQuery({ context: BLOG_CLIENT })
  const { data: blogs } = useBlogsQuery({
    variables: { take: 10 },
    context: { clientName: BLOG_CLIENT },
  })
  const blogsData: { data: PreviewPostFragment; count: number }[] =
    blogs?.tag?.authorsStats?.map((stat) => ({
      data: {
        id: stat.user.id,
        authors: [],
        excerpt: stat.user.bio,
        pageviews: 0,
        readingTime: 0,
        slug: stat.user.slug,
        picture: stat.user.cover,
        tags: [],
        title: stat.user.bio,
      },
      count: stat.user.countPosts,
    }))

  return (
    <>
      <Stories className={rootStyles.stories} />

      {nowReading?.nowReadingPosts?.length && (
        <div className={rootStyles.popular}>
          <CommonSlider
            title="Популярное"
            items={nowReading.nowReadingPosts.map((post) => (
              <Post
                className={styles.item}
                data={post}
                imageMaxWidth={400}
                showTags
                bigTitle
                sliceTags
                sizes="(max-width: 48rem) 256px, (max-width: 64rem) 270px, (max-width: 64rem) 270px, (max-width: 90rem) 400px, 540px"
              />
            ))}
            showTags
            bigTitle
            sliceTags
            slidesPerView={3}
          />
        </div>
      )}
      <div className={rootStyles.banner}>
        <CommonBanner />
      </div>
      {blogsData?.length && (
        <CommonSlider
          title="Блоги компаний"
          href="/tag/blog"
          withTopPadding
          items={blogsData.map((post) => (
            <Post
              className={styles.item}
              data={post.data}
              imageMaxWidth={245}
              showPosts
              posts={post.count}
              href="/author"
              sizes="(max-width: 425px) 165px, (max-width: 1440px) 175px, 245px"
            />
          ))}
          slidesPerView={6}
        />
      )}

      {telegramData?.nodes?.length && (
        <CommonSlider
          title="Телеграм"
          href="/tag/tg"
          total={telegramData.totalCount}
          items={telegramData.nodes.map((post) => (
            <Post
              className={styles.item}
              data={post}
              imageMaxWidth={245}
              sizes="(max-width: 425px) 165px, (max-width: 1440px) 175px, 245px"
            />
          ))}
          showTags
          bigTitle
          sliceTags
          slidesPerView={6}
        />
      )}

      <WorkProvider>
        <Work />
      </WorkProvider>

      <SocialsBanner />

      {stData?.nodes?.length && (
        <CommonSlider
          title="Полезное"
          href="/tag/st"
          total={stData.totalCount}
          withTopPadding
          items={stData.nodes.map((post) => (
            <Post
              className={styles.item}
              data={post}
              imageMaxWidth={245}
              sizes="(max-width: 425px) 165px, (max-width: 1440px) 175px, 245px"
            />
          ))}
          showTags
          bigTitle
          sliceTags
          slidesPerView={6}
        />
      )}
      {reviewsData?.nodes?.length && (
        <CommonSlider
          title="Обзоры"
          href="/tag/reviews"
          total={reviewsData.totalCount}
          items={reviewsData.nodes.map((post) => (
            <Post
              className={styles.item}
              data={post}
              imageMaxWidth={245}
              sizes="(max-width: 425px) 165px, (max-width: 1440px) 175px, 245px"
            />
          ))}
          showTags
          bigTitle
          sliceTags
          slidesPerView={6}
        />
      )}
      {interviewData?.nodes?.length && (
        <CommonSlider
          title="Интервью"
          className={styles.lastSlider}
          href="/tag/in"
          total={interviewData.totalCount}
          items={
            interviewData.nodes.map((post) => (
              <Post
                className={styles.item}
                data={post}
                imageMaxWidth={245}
                sizes="(max-width: 425px) 165px, (max-width: 1440px) 175px, 245px"
              />
            )) || []
          }
          showTags
          bigTitle
          sliceTags
          slidesPerView={6}
        />
      )}
    </>
  )
}

export default MainPageContent
